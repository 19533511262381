require('dotenv').config();
const fs = require('fs');

const SENTRY_RELAY = process.env.SENTRY_RELAY;
const SENTRY_DSN = process.env.SENTRY_DSN;
const DOMAIN = process.env.DOMAIN;
const CI_ENVIRONMENT_NAME = process.env.CI_ENVIRONMENT_NAME;
const CI_PROJECT_PATH_SLUG = process.env.CI_PROJECT_PATH_SLUG;
const CI_COMMIT_SHORT_SHA = process.env.CI_COMMIT_SHORT_SHA;

const FALLBACK_SENTRY_DSN = `${DOMAIN}/error-logs`;
let DSN = SENTRY_DSN;
let USING_SYSTEM_NAME_SNIPPET = `usingSystemName: OwError.SYSTEM.SENTRY,`;
let SENTRY_CONFIG_SNIPPET = '';

if (SENTRY_DSN) {
  if (SENTRY_RELAY == 1) {
    DSN = SENTRY_DSN.replace('sentry.oskarwegner.pl', FALLBACK_SENTRY_DSN)
  }

  SENTRY_CONFIG_SNIPPET = `
    [OwError.SYSTEM.SENTRY]: {
      config: {
        dsn: "${DSN}",
        tracesSampleRate: 0.2,
        integrations: [new Sentry.Integrations.BrowserTracing()],
        debug: false,
        environment: '${CI_ENVIRONMENT_NAME}',
        release: '${CI_PROJECT_PATH_SLUG}@${CI_COMMIT_SHORT_SHA}',
        ignoreErrors: [
          'Non-Error exception captured',
        ],
      },
    },
  `
}

const SCRIPT = `
window.addEventListener('load', () => {
  window.owError = new OwError({
    config: {
      ${USING_SYSTEM_NAME_SNIPPET}
      ${SENTRY_CONFIG_SNIPPET}
    }
  });
});
`

fs.writeFileSync(`${__dirname}/ow-error-system.init.gitignore.js`, SCRIPT, 'utf8');
