class OwError {
  static addSystem(system) {
    OwError.AVAILABLE_SYSTEMS[system.name] = system;
  }

  get usingSystemName() {
    return this.config.usingSystemName;
  }

  get usingSystem() {
    return OwError.AVAILABLE_SYSTEMS[this.usingSystemName];
  }

  constructor({config}) {
    this.config = config;
    this.initSystem();
    this.clearErrors();
  }

  initSystem() {
    if (!this.usingSystemName) {
      console.warn(`OwSystem don't have using system name`)
      return;
    }

    if (!this.config[this.usingSystemName]) {
      console.warn(`OwSystem don't have config for ${this.usingSystemName}`)
      return;
    }

    if (OwError.AVAILABLE_SYSTEMS && Object.keys(OwError.AVAILABLE_SYSTEMS).length === 0) {
      console.warn(`OwSystem don't have any available system`)
      return;
    }

    const system = this.config[this.usingSystemName];
    this.usingSystem.init(system.config);
  }

  clearErrors() {
    this.errors = [];
  }

  pushError(error) {
    const errorExistInList = this.errors.some(_error => _error.toString() == error.toString());
    if (errorExistInList) {
      return;
    }

    this.errors.push(error);
    this.sendError({error});
  }

  sendError({error}) {
    this.usingSystem.sendError({error});
  }

  sendMessage({message, level}) {
    this.usingSystem.sendMessage({message, level});
  }

  static getPlayer() {
    return window['storePlayer'];
  }

  static getUser() {
    return window['storeUser'];
  }

  static getResolution() {
    return {
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
      devicePixelRatio: window.devicePixelRatio,
    }
  }

  static getOtherInformation() {
    return {
      isSupportedWebGL: window.isSupportedWebGL,
    }
  }
}

OwError.SYSTEM = {}
OwError.AVAILABLE_SYSTEMS = {};
